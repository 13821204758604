import React, { useEffect, useState } from 'react';
import './styles/Home.css';
import HomeTextOverlay from './assets/images/Text_takeYourMissionToTheCloud.png';
import SystemsBadge from './assets/images/Icon_systems.png';
import UnleashingTextOverlay from './assets/images/Text_unleashing.png';
import UnleashingBG from './assets/images/Background_unleashing.jpg';
import LTWimage from './assets/images/Image_leading the way.png';
import Partnership from './assets/images/Image_reliable partnership.png';
import Bar from './assets/images/bar.png';
import Grid from "@mui/material/Grid";
import { initializeMatomo, logToMatomo } from './utils/matomoOperations.js'

function Home() {
    const [isMainTextVisible, setIsMainTextVisible] = useState(false);
    const [isBadgeTextVisible, setIsBadgeTextVisible] = useState(false);

    useEffect(() => {
        initializeMatomo();
        logToMatomo(['trackEvent', 'view', 'Home']);
        // Trigger the main text animation with a one-second delay when the component is mounted
        setTimeout(() => {
            setIsMainTextVisible(true);
        }, 600);

        // Trigger the badge text animation with a two-second delay when the component is mounted
        setTimeout(() => {
            setIsBadgeTextVisible(true);
        }, 1200);
    }, []);

    return (
        <div id="Home" className="home-container">
            <Grid>
            {/* Take Your Mission to the Cloud */}
            <div className="background-image">
                <div className= 'text-overlay'>

                    {/* Title Text Image */}
                    <img src={HomeTextOverlay} alt="Home Text Overlay"/>
                    <p className={`p1 ${isMainTextVisible ? 'drop-in-animation visible' : 'drop-in-animation'}`}>Cloud One is the preeminent enterprise cloud available to the DoD and managed by the Air Force. A multi-cloud, multi-vendor ecosystem, Cloud One is a one-stop shop for DoD mission application owners that require a full array of commercial cloud services, delivering speed, scale and security with cutting-edge technologies, approaches and solutions for cloud computing.</p>
                    
                    {/* Systems Badges */}
                    <div className="badges">
                        <div className="d-flex flex-column">
                            <img src={SystemsBadge} alt="Zone C Systems"/>
                            <p className={`p1 ${isMainTextVisible ? 'fade-in-animation visible' : 'fade-in-animation'}`}>Zone C</p>
                            <p className={`p2 ${isBadgeTextVisible ? 'drop-in-animation visible' : 'drop-in-animation'}`}>222 Systems</p>
                        </div>
                        <div className="d-flex flex-column"> 
                            <img src={SystemsBadge} alt="Production IL2-IL6 Systems" />
                            <p className={`p1 ${isMainTextVisible ? 'fade-in-animation visible' : 'fade-in-animation'}`}>Production IL2-IL6</p>
                            <p className={`p2 ${isBadgeTextVisible ? 'drop-in-animation visible' : 'drop-in-animation'}`}>158 Systems</p>
                        </div>
                    </div>
                </div>
            </div>
           
            {/* Move Your Mission App to Cloud One */}
            <div className="ma-background">
                <p className='p1' style={{
                width: "100%"
              }}>Move Your Mission App to Cloud One</p>
                <div className="ma-table">
                    <div className="ma-icon-group">
                        <div className="ma-icon cost" />
                        <div className="ma-icon scal" />
                        <div className="ma-icon perf" />
                        <div className="ma-icon ato" />
                    </div>
                    <div className="ma-word-group">
                        <div><p>Lower operating costs</p></div>
                        <div><p>Automatic scaling to match demand</p></div>
                        <div><p>Increased reliability and performance</p></div>
                        <div><p>Up to 40% ATO inheritance (on average)</p></div>
                    </div>
                </div>
            </div>
            
            
            {/* Unleashing The Power of The Cloud */}
            <div className="unleash">
                <div className='UnleashBG'>
                    <img src={UnleashingBG} alt="Unleashing The Power of The Cloud Background"/>
                </div>

                <div className='UnleashTxtOverlay'>
                    <img src={UnleashingTextOverlay} alt="Unleashing The Power of The Cloud Txt Overlay"/>
                </div>
                <p>Chartered by the Air Force Life Cycle Management Center (AFLCMC) / HNII Division to lead the way for the Air and Space Force missions as the leaders in cloud hosting. Our Mission is to provide common secure computing environments, standardized platforms, application migration and support services, and data management.</p>
            </div>
            

            {/* Leading the Way */}
            <div className="LTW-section" style={{color: "white"}}>
                <div className="blue-boxhome">
                    <h3>Leading the way</h3>
                </div>
                <div className="content" style={{ marginTop: '6vh', marginLeft: "9vw" }}>
                    <div className="Bar">
                        <img src={Bar} alt="" style={{ height: '75%', marginTop: '-15.5vh' }}/>
                        <p>Cloud One is the leading Air Force provider of state-of-the-art cloud computing platforms, technologies, approaches, and solutions.<br /><br />Initiated in 2017, we quickly stood up the Air Force’s most robust cloud services and hosting platform. Today, Cloud One continues to evolve through progressive enhancements to our own core offerings, plus adopting platform innovations that our Government Cloud Service Providers drive as part of their own product development efforts.</p>
                    </div>
                    <img src={LTWimage} alt="" />
                </div>
            </div>

            {/* Reliable Partnership */}
            <div className="LTW-section" style={{color: "rgb(24, 32, 81)"}}>
                <h3 style={{marginTop: "8vh", marginLeft: "4vw"}}>Reliable Partnership</h3>
                <div className="content" style={{ marginTop: '-1vh', marginLeft: "9vw" }}>
                    <div className="Bar">
                        <img src={Bar} alt="" style={{ height: '65%', marginTop: '-18.5vh' }}/>
                        <p>Our knowledgeable and responsive Cloud One team is here to help deliver a smooth migration process by offering a reliable partnership that stays with you through the lifecycle of your App integration and delivery.<br /><br />We support migration vendors and application development teams, every step of the way, ensuring a successful cloud migration.</p>
                    </div>
                    <img src={Partnership} alt="" />
                </div>
            </div>
            </Grid>
        </div>
    );
}

export default Home;